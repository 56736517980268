import { withDependencies } from '@wix/thunderbolt-ioc'
import { Experiments, ExperimentsSymbol, PlatformEnvDataProvider, SiteAssetsClientSym, ViewerModel, ViewerModelSym } from '@wix/thunderbolt-symbols'
import { SiteAssetsClientAdapter, FallbackStrategy, getFallbackOverrideStrategy } from 'thunderbolt-site-assets-client'

export const siteAssetsEnvDataProvider = withDependencies(
	[ExperimentsSymbol, SiteAssetsClientSym, ViewerModelSym],
	(experiments: Experiments, siteAssetsClient: SiteAssetsClientAdapter, viewerModel: ViewerModel): PlatformEnvDataProvider => {
		const { siteAssets, deviceInfo } = viewerModel
		const clientInitParams = {
			deviceInfo,
			siteAssetsClientConfig: siteAssetsClient.getInitConfig(),
			fallbackStrategy:
				getFallbackOverrideStrategy(experiments, 'clientWorker') || ((experiments['specs.thunderbolt.shouldEnableSACFallbackForClientWorker'] ? 'enable' : 'disable') as FallbackStrategy)
		}

		return {
			platformEnvData: {
				siteAssets: {
					...siteAssets,
					clientInitParams
				}
			}
		}
	}
)
